var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, put, call } from 'redux-saga/effects';
import { post, putMethod } from '../../utils/api';
import settings from '../../utils/settings';
import { ActionTypes } from './constants';
import { get as lodashGet, isEmpty } from 'lodash';
import { getToken } from '../../utils/helper';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAccountDataFlow() {
    var request, _a, cbk, _b, body, _c, isUpdate, _d, successCbk, response, success, result, isUnauthorized, drsServiceRoles, installerId, isInstaller, isTier1Admin, isTier2Admin, isReviewer, isServiceAdmin, userEmail, details, isLoginAs, roles, viewOnlyRole, viewCannotEdit, parentUserRoles, error_1;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!true) return [3 /*break*/, 17];
                return [4 /*yield*/, take(ActionTypes.ACCOUNT_DETAIL_REQUEST)];
            case 1:
                request = _e.sent();
                _a = request.payload, cbk = _a.cbk, _b = _a.body, body = _b === void 0 ? {} : _b, _c = _a.isUpdate, isUpdate = _c === void 0 ? false : _c, _d = _a.successCbk, successCbk = _d === void 0 ? function () { } : _d;
                _e.label = 2;
            case 2:
                _e.trys.push([2, 14, , 16]);
                response = void 0;
                if (!isUpdate) return [3 /*break*/, 4];
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getApiUrl("api_internal/account?service_roles=1"),
                        body: body,
                    })];
            case 3:
                response = _e.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(post, {
                    url: settings.getDrsUrl("proxyApi/request"),
                    body: { url: [settings.getApiUrl('api_internal/account')], method: ['GET'], service_roles: ['1'] },
                    headers: { Authorization: getToken(), 'Content-Type': 'application/json' },
                })];
            case 5:
                response = _e.sent();
                _e.label = 6;
            case 6:
                success = response.success, result = response.result, isUnauthorized = response.isUnauthorized;
                drsServiceRoles = lodashGet(response, 'result.service_roles.drs_service_role', 'no_access');
                installerId = lodashGet(response, 'result.company_id', -1);
                isInstaller = drsServiceRoles === 'no_access';
                isTier1Admin = drsServiceRoles === 'tier_1_admin';
                isTier2Admin = drsServiceRoles === 'tier_2_admin';
                isReviewer = drsServiceRoles === 'reviewer';
                isServiceAdmin = drsServiceRoles === 'service_admin';
                userEmail = lodashGet(response, 'result.email', '');
                if (!success) return [3 /*break*/, 11];
                if (!isUpdate) return [3 /*break*/, 8];
                return [4 /*yield*/, put({ type: ActionTypes.UPDATE_ACCOUNT_SUCCESS, message: result.message })];
            case 7:
                _e.sent();
                successCbk();
                return [3 /*break*/, 10];
            case 8:
                details = response.result;
                isLoginAs = lodashGet(details, 'login_as', false);
                roles = lodashGet(details, 'roles', []);
                viewOnlyRole = roles.find(function (role) { return role === 'support_read'; });
                viewCannotEdit = false;
                if (isEmpty(viewOnlyRole) && isLoginAs) {
                    parentUserRoles = lodashGet(details, 'login_user.roles', []);
                    viewOnlyRole = parentUserRoles.find(function (role) { return role === 'support_read'; });
                    if (!isEmpty(viewOnlyRole)) {
                        viewCannotEdit = true;
                    }
                }
                return [4 /*yield*/, put({
                        type: ActionTypes.ACCOUNT_DETAIL_SUCCESS,
                        installerId: installerId,
                        isInstaller: isInstaller,
                        isTier1Admin: isTier1Admin,
                        isTier2Admin: isTier2Admin,
                        isReviewer: isReviewer,
                        isServiceAdmin: isServiceAdmin,
                        userEmail: userEmail,
                        accountDetail: details,
                        locale: details.locale,
                        viewOnlyAccess: !isEmpty(viewOnlyRole),
                        viewCannotEdit: viewCannotEdit,
                        country: details.country,
                    })];
            case 9:
                _e.sent();
                cbk(result);
                _e.label = 10;
            case 10: return [3 /*break*/, 13];
            case 11: return [4 /*yield*/, put({
                    type: ActionTypes.ACCOUNT_DETAIL_FAILURE,
                    error: result.message || result.messages,
                    isUnauthorized: isUnauthorized,
                    isInstaller: !isEmpty(result.message) && result.message.includes('Connection reset by peer') ? isInstaller : false,
                })];
            case 12:
                _e.sent();
                cbk(result);
                _e.label = 13;
            case 13: return [3 /*break*/, 16];
            case 14:
                error_1 = _e.sent();
                // eslint-disable-next-line no-console
                console.error('Saga Error in getting Account Details');
                return [4 /*yield*/, put({ type: ActionTypes.ACCOUNT_DETAIL_FAILURE, error: error_1, isUnauthorized: false })];
            case 15:
                _e.sent();
                return [3 /*break*/, 16];
            case 16: return [3 /*break*/, 0];
            case 17: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSiteDetailsFlow() {
    var request, _a, siteId, response, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(ActionTypes.GET_SITE_DETAILS_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                _a = request.payload.siteId, siteId = _a === void 0 ? '' : _a;
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("proxyApi/request"),
                        body: {
                            url: [settings.getApiUrl('api_internal/sites/search')],
                            method: ['GET'],
                            search_value: [siteId],
                            search_type: ['all_stages'],
                        },
                        headers: { Authorization: getToken(), 'Content-Type': 'application/json' },
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: ActionTypes.GET_SITE_DETAILS_SUCCESS, siteDetails: response.result.result })];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ActionTypes.GET_SITE_DETAILS_FAILURE, error: response.result.message })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _b.sent();
                return [4 /*yield*/, put({ type: ActionTypes.GET_SITE_DETAILS_FAILURE, error: error_2 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
