var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../../components/PageHeader';
import ProjectDetails from '../Installer/ProjectDetails';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withLoadingScreen from '../hoc/withLoadingScreen';
import { Box, Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import I18n from '../../config/locales';
import getConfig from '../../config/env';
var useStyles = makeStyles(function (theme) { return ({
    root: __assign(__assign({}, theme.mixins.gutters()), { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2), backgroundColor: '#FBFBFB' }),
    topPanel: {
        justifyContent: 'space-between',
        backgroundColor: '#33597721',
        color: '#335977',
        padding: theme.spacing(1.7, 2.5),
        marginBottom: theme.spacing(1),
        display: 'flex',
    },
    applyForCC: {
        color: '#0e2d45',
    },
}); });
var DesignReviewService = function (props) {
    var classes = useStyles();
    var univCourseLpId = get(props.application, 'univ_course_lp_id');
    return (_jsxs("div", { className: classes.root, children: [!isEmpty(get(props.application, 'drs_id', '')) && (_jsxs(Box, { className: classes.topPanel, children: [_jsx(Typography, { children: I18n.t('designReviewService.requestedId') + ': ' + get(props.application, 'drs_id', '') }), univCourseLpId && (_jsx(Typography, { className: classes.applyForCC, children: "".concat(I18n.t('designReviewService.enphaseStorageCourse'), " ").concat(getConfig()[univCourseLpId]) }))] })), _jsx(PageHeader, { text: I18n.t('applicationStatus.pageHeader') }), _jsx(ProjectDetails, {})] }));
};
var mapStateToProps = function (state) { return ({
    application: state.installerReducer.application,
}); };
export default compose(connect(mapStateToProps), function (Component) { return withLoadingScreen(Component, ['application']); })(DesignReviewService);
