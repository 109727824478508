import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { forwardTo, setToken, getToken, parseParams, isValidToken } from '../../utils/helper';
import { setMobileView, setEnlightenManagerToken, getAccountData } from './action';
import { setDrsId, setSiteId } from '../Installer/action';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumbs from '../../components/Breadcrumbs';
import settings from '../../utils/settings';
import I18n from '../../components/Temp/config/locales/e_i18n';
import getMoment from '../../components/Temp/components/moment';
import { browserHistory as history } from '../../routes/index';

let isTokenMissing = false;

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 220px)',
    marginTop: theme.spacing(7),
  },
}));

const Layout = ({
  children,
  // isMobileView,
  matchProps,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Header {...history} {...matchProps} />
      <Box className={classes.root}>
        <Breadcrumbs matchProps={matchProps} isInstaller={false} history={history} />
        {children}
      </Box>
      <a id="login_screen" href={settings.enlightenUrl('login')} referrerPolicy="no-referrer-when-downgrade" />
      <Footer {...history} {...matchProps} />
    </Fragment>
  );
};

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    let token = getToken();
    const { token: urlToken } = parseParams(search);
    if (!isValidToken(token)) {
      if (isValidToken(urlToken)) {
        token = urlToken;
        setToken(token);
      } else {
        isTokenMissing = true;
      }
    }
    const { site_id: siteId, drs_id: drsId } = props.computedMatch.params;
    if (token && pathname) {
      forwardTo(pathname);
    }
    if (token) {
      this.props.setEnlightenManagerToken(token);
    }
    if (siteId) {
      this.props.setSiteId(siteId);
    }
    if (drsId) {
      this.props.setDrsId(drsId);
    }
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 600;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
    // log out the person here if he is not type of admin
    this.props.getAccountData({
      cbk: () => {},
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
    if (isTokenMissing) {
      document.getElementById('login_screen').click();
    }
  };

  componentDidUpdate() {
    I18n.defaultLocale = 'en';
    I18n.locale = this.props.locale || '';
    I18n.fallbacks = true;
    getMoment(this.props.locale);
  }

  render() {
    const { component: Component, isMobileView, loading, accountDetail, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Layout isMobileView={isMobileView} loading={loading} matchProps={matchProps}>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMobileView: (isMobileView) => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: (tokenVal) => dispatch(setEnlightenManagerToken(tokenVal)),
  setSiteId: (siteId) => dispatch(setSiteId(siteId)),
  setDrsId: (drsId) => dispatch(setDrsId(drsId)),
  getAccountData: (payload) => dispatch(getAccountData(payload)),
});

const mapStateToProps = (state) => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading,
  accountDetail: state.appReducer.accountDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
