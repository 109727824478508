var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, put, call, takeLeading } from 'redux-saga/effects';
import { deleteMethod, get, post, putMethod } from '../../utils/api';
import { InstallerActionTypes } from './constants';
import settings from '../../utils/settings';
import { loadTemplateDownloadLabel } from '../../../lib/utils/constant';
import { isEmpty, omit, get as getLodash } from 'lodash';
import { getToken } from '../../utils/helper';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createDrsApplicationFlow() {
    var request, _a, application, siteId, successCbk, courseId, type, installerCountry, termsAccepted, response, success, status_1, result, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 14];
                return [4 /*yield*/, take(InstallerActionTypes.CREATE_APPLICATION_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, application = _a.application, siteId = _a.siteId, successCbk = _a.successCbk, courseId = _a.courseId, type = _a.type, installerCountry = _a.installerCountry, termsAccepted = _a.termsAccepted;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 11, , 13]);
                response = void 0;
                if (!!isEmpty(getLodash(application, 'drs_id', ''))) return [3 /*break*/, 4];
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("applications/".concat(application.drs_id)),
                        withBearer: true,
                        body: application,
                    })];
            case 3:
                response = _b.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(post, {
                    url: settings.getDrsUrl("applications/".concat(siteId, "/new?univ_course_lp_id=").concat(courseId, "&project_type=").concat(type, "&installer_country=").concat(installerCountry, "&terms_accepted=").concat(termsAccepted)),
                    withBearer: true,
                })];
            case 5:
                response = _b.sent();
                _b.label = 6;
            case 6:
                success = response.success, status_1 = response.status, result = response.result;
                if (!(success && status_1 === 200)) return [3 /*break*/, 8];
                return [4 /*yield*/, put({
                        type: InstallerActionTypes.CREATE_APPLICATION_SUCCESS,
                        application: result,
                        message: 'Application saved',
                    })];
            case 7:
                _b.sent();
                if (!isEmpty(getLodash(result, 'drs_id', '')) && getLodash(result, 'current_status.state_id', 0) === 0) {
                    successCbk(result.drs_id);
                }
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, put({
                    type: InstallerActionTypes.CREATE_APPLICATION_FAILURE,
                    message: result.message || result.messages,
                })];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [3 /*break*/, 13];
            case 11:
                error_1 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: createDrsApplicationFlow:', error_1);
                return [4 /*yield*/, put({ type: InstallerActionTypes.CREATE_APPLICATION_FAILURE, message: error_1 })];
            case 12:
                _b.sent();
                return [3 /*break*/, 13];
            case 13: return [3 /*break*/, 0];
            case 14: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDrsApplicationFlow() {
    var request, drsId, successCbk, response, success, status_2, result, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.DRS_GET_APPLICATION_REQUEST)];
            case 1:
                request = _a.sent();
                drsId = request.drsId, successCbk = request.successCbk;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("applications/".concat(drsId)),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                success = response.success, status_2 = response.status, result = response.result;
                if (!(success && status_2 === 200)) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: InstallerActionTypes.DRS_GET_APPLICATION_SUCCESS,
                        application: result,
                        message: 'Application retrieved',
                    })];
            case 4:
                _a.sent();
                if (successCbk) {
                    successCbk(result.site_id);
                }
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: InstallerActionTypes.DRS_GET_APPLICATION_FAILURE,
                    message: result.message || result.messages,
                })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getDrsApplicationFlow:', error_2);
                return [4 /*yield*/, put({ type: InstallerActionTypes.DRS_GET_APPLICATION_FAILURE, message: error_2 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUserSelectionDataFlow() {
    var response, userSelectionData, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, take(InstallerActionTypes.GET_USER_SELECTION_DATA_REQUEST)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 8]);
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("userSelectionData/"),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                userSelectionData = omit(response.result, 'id') || {};
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_USER_SELECTION_DATA_SUCCESS, userSelectionData: userSelectionData })];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_3 = _a.sent();
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_USER_SELECTION_DATA_FAILURE, error: error_3 })];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
var fileLoading = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFileLinkFlow() {
    var request, _a, fileName, methodType, successCb, failureCb, requestObj, response, _b, success, status_3, result, error_4;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!true) return [3 /*break*/, 14];
                return [4 /*yield*/, take(InstallerActionTypes.GET_S3_URL_REQUEST)];
            case 1:
                request = _c.sent();
                _a = request.payload, fileName = _a.fileName, methodType = _a.methodType, successCb = _a.successCb, failureCb = _a.failureCb;
                if (!!fileLoading) return [3 /*break*/, 13];
                fileLoading = true;
                _c.label = 2;
            case 2:
                _c.trys.push([2, 11, , 13]);
                requestObj = {
                    url: settings.getDrsUrl("applications/s3url/".concat(fileName)),
                    withBearer: true,
                };
                if (!(methodType === 'DELETE')) return [3 /*break*/, 4];
                return [4 /*yield*/, call(deleteMethod, requestObj)];
            case 3:
                _b = _c.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, call(get, requestObj)];
            case 5:
                _b = _c.sent();
                _c.label = 6;
            case 6:
                response = _b;
                success = response.success, status_3 = response.status, result = response.result;
                if (!(success && status_3 === 200)) return [3 /*break*/, 8];
                fileLoading = false;
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_S3_URL_SUCCESS, preSignedS3Url: result.presigned_url })];
            case 7:
                _c.sent();
                successCb(result.presigned_url);
                return [3 /*break*/, 10];
            case 8:
                fileLoading = false;
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_S3_URL_FAILURE, error: response.result.message })];
            case 9:
                _c.sent();
                failureCb();
                _c.label = 10;
            case 10: return [3 /*break*/, 13];
            case 11:
                error_4 = _c.sent();
                fileLoading = false;
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_S3_URL_FAILURE, error: error_4 })];
            case 12:
                _c.sent();
                failureCb();
                return [3 /*break*/, 13];
            case 13: return [3 /*break*/, 0];
            case 14: return [2 /*return*/];
        }
    });
}
var putS3loading = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function uploadToS3Flow(request) {
    var _a, preSignedS3Url, fileObj, successCbS3_1, failureCbS3_1, _b, isDownload, requestOptions, error_5;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!!putS3loading) return [3 /*break*/, 4];
                putS3loading = true;
                _a = request.payload, preSignedS3Url = _a.preSignedS3Url, fileObj = _a.fileObj, successCbS3_1 = _a.successCbS3, failureCbS3_1 = _a.failureCbS3, _b = _a.isDownload, isDownload = _b === void 0 ? false : _b;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 2, , 4]);
                requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': isDownload ? 'application/octet-stream' : fileObj.type },
                    body: fileObj,
                };
                fetch(preSignedS3Url, requestOptions).then(function (data) {
                    var status = data.status;
                    if (status === 200) {
                        putS3loading = false;
                        successCbS3_1();
                    }
                    else {
                        putS3loading = false;
                        failureCbS3_1();
                    }
                });
                return [3 /*break*/, 4];
            case 2:
                error_5 = _c.sent();
                putS3loading = false;
                return [4 /*yield*/, put({ type: InstallerActionTypes.PUT_TO_S3_FAILURE, error: error_5 })];
            case 3:
                _c.sent();
                failureCbS3_1();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function watchLastPutS3Flow() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(InstallerActionTypes.PUT_TO_S3_REQUEST, uploadToS3Flow)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var downloadingFile = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFileDownloadFlow() {
    var request, _a, drsId, fileName, successCb, failureCb, requestObj, response, success, status_4, result, error_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.GET_S3_DOWNLOAD_URL_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, drsId = _a.drsId, fileName = _a.fileName, successCb = _a.successCb, failureCb = _a.failureCb;
                if (!!downloadingFile) return [3 /*break*/, 10];
                downloadingFile = true;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                requestObj = {
                    url: settings.getDrsUrl("applications/".concat(drsId, "/s3url/?file_ids=").concat(fileName)),
                    withBearer: true,
                };
                return [4 /*yield*/, call(get, requestObj)];
            case 3:
                response = _b.sent();
                success = response.success, status_4 = response.status, result = response.result;
                if (!(success && status_4 === 200)) return [3 /*break*/, 5];
                downloadingFile = false;
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_S3_DOWNLOAD_URL_SUCCESS, preSignedS3Urls: result.presigned_urls })];
            case 4:
                _b.sent();
                successCb(result.presigned_urls, fileName);
                return [3 /*break*/, 7];
            case 5:
                downloadingFile = false;
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_S3_DOWNLOAD_URL_FAILURE, error: response.result.message })];
            case 6:
                _b.sent();
                failureCb();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_6 = _b.sent();
                downloadingFile = false;
                // eslint-disable-next-line no-console
                console.log('Saga: getFileDownloadFlow:', error_6);
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_S3_DOWNLOAD_URL_FAILURE, error: error_6 })];
            case 9:
                _b.sent();
                failureCb();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getCartUrlFlow() {
    var request, drsId, successCbk, failureCbk, response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.GET_CART_URL_REQUEST)];
            case 1:
                request = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                drsId = request.drsId, successCbk = request.successCbk, failureCbk = request.failureCbk;
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("payments/".concat(drsId, "/cart")),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_CART_URL_SUCCESS, cartUrl: response.result.cart_url })];
            case 4:
                _a.sent();
                successCbk(response.result.cart_url, drsId);
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: InstallerActionTypes.GET_CART_URL_FAILURE,
                    error: response.result.message,
                })];
            case 6:
                _a.sent();
                failureCbk(drsId);
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_7 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getCartUrlFlow:', error_7);
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_CART_URL_FAILURE, error: error_7 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postNpsFeedbackFlow() {
    var request, _a, body, drsId, successCbk, response, error_8;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.POST_NPS_FEEDBACK_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                _a = request.payload, body = _a.body, drsId = _a.drsId, successCbk = _a.successCbk;
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("nps/".concat(drsId, "/new")),
                        withBearer: true,
                        body: body,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: InstallerActionTypes.POST_NPS_FEEDBACK_SUCCESS })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: InstallerActionTypes.POST_NPS_FEEDBACK_FAILURE,
                    error: response.result.message,
                })];
            case 6:
                _b.sent();
                successCbk();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_8 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: postNpsFeedbackFlow:', error_8);
                return [4 /*yield*/, put({ type: InstallerActionTypes.POST_NPS_FEEDBACK_FAILURE, error: error_8 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resubmissionReceivedFlow() {
    var request, _a, files, comment, drsId, successCbk, response, error_9;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.RESUBMISSION_RECEIVED_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                _a = request.payload, files = _a.files, comment = _a.comment, drsId = _a.drsId, successCbk = _a.successCbk;
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("applications/".concat(drsId, "/resubmissionReceived?comment=").concat(comment)),
                        withBearer: true,
                        body: files,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: InstallerActionTypes.RESUBMISSION_RECEIVED_SUCCESS })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: InstallerActionTypes.RESUBMISSION_RECEIVED_FAILURE,
                    error: response.result.message,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_9 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: resubmissionReceivedFlow:', error_9);
                return [4 /*yield*/, put({ type: InstallerActionTypes.RESUBMISSION_RECEIVED_FAILURE, error: error_9 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
export function getNpsFeedbacksFlow() {
    var request, drsId, response, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.GET_NPS_FEEDBACK_REQUEST)];
            case 1:
                request = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                drsId = request.drsId;
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("nps/".concat(drsId)),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_NPS_FEEDBACK_SUCCESS, feedbacks: response.result })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: InstallerActionTypes.GET_NPS_FEEDBACK_FAILURE,
                    error: response.result.message,
                })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_10 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getNpsFeedbacksFlow:', error_10);
                return [4 /*yield*/, put({ type: InstallerActionTypes.GET_NPS_FEEDBACK_FAILURE, error: error_10 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
var download = function (countryCode) { return __awaiter(void 0, void 0, void 0, function () {
    var requestOptions, res, blob, newBlob, newUrl, link, loadTemplateDownloadedLabel;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                requestOptions = {
                    method: 'GET',
                    headers: { Authorization: "Bearer ".concat(getToken()) },
                };
                return [4 /*yield*/, fetch(settings.getDrsUrl("applications/downloadLoadCalculationFile?countryCode=".concat(countryCode)), requestOptions)];
            case 1:
                res = _a.sent();
                return [4 /*yield*/, res.blob()];
            case 2:
                blob = _a.sent();
                newBlob = new Blob([blob]);
                newUrl = window.URL.createObjectURL(newBlob);
                link = document.createElement('a');
                link.href = newUrl;
                loadTemplateDownloadedLabel = Object.keys(loadTemplateDownloadLabel).indexOf(countryCode) > -1
                    ? loadTemplateDownloadLabel[countryCode]
                    : 'Backup Load details.xlsx';
                link.setAttribute('download', loadTemplateDownloadedLabel);
                document.body.appendChild(link);
                link.click();
                return [2 /*return*/];
        }
    });
}); };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function downloadLoadCalculationFileFlow() {
    var request, countryCode, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 7];
                return [4 /*yield*/, take(InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_REQUEST)];
            case 1:
                request = _a.sent();
                countryCode = request.countryCode;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 6]);
                download(countryCode);
                return [4 /*yield*/, put({ type: InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_SUCCESS })];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                error_11 = _a.sent();
                return [4 /*yield*/, put({ type: InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_FAILURE, error: error_11 })];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [3 /*break*/, 0];
            case 7: return [2 /*return*/];
        }
    });
}
// this is for only university application which is free
export function savePaymentDetailsFlow() {
    var request, _a, drsId, successCbk, response, error_12;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(InstallerActionTypes.SAVE_PAYMENT_DETAILS_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                _a = request.payload, drsId = _a.drsId, successCbk = _a.successCbk;
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("payments/".concat(drsId, "/universityPayment")),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: InstallerActionTypes.SAVE_PAYMENT_DETAILS_SUCCESS })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: InstallerActionTypes.SAVE_PAYMENT_DETAILS_FAILURE,
                    error: response.result.message,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_12 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: savePaymentDetailsFlow:', error_12);
                return [4 /*yield*/, put({ type: InstallerActionTypes.SAVE_PAYMENT_DETAILS_FAILURE, error: error_12 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
