var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Accordion, Typography, AccordionDetails, Box, Button, IconButton, Paper } from '@material-ui/core';
import clsx from 'clsx';
import Solid from '../images/Solid';
import Forward from '../images/Forward';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { APPLICATION_STATES } from '../../../../utils/constant';
import I18n from '../../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    panelWrapper: {
        maxHeight: '168px',
        minHeight: '168px',
        marginBottom: '15px',
    },
    columnFlex: {
        flexDirection: 'column',
    },
    bottomPadding: {
        paddingBottom: theme.spacing(2),
    },
    maxWidthBox: {
        maxWidth: '528px',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxHeight: '168px',
        minHeight: '168px',
        '& .MuiPaper-outlined': {
            border: 'none',
        },
    },
    displayFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexDirectionColumn: {
        flexDirection: 'column',
    },
    boxWrapper: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        maxHeight: '168px',
        minHeight: '168px',
    },
    buttons: {
        fontSize: 16,
        cursor: 'pointer',
        textAlign: 'center',
    },
    highlightedColor: {
        color: '#016AE3',
    },
    disabledColor: {
        color: '#919191',
        pointerEvents: 'none',
    },
    buttonFeedback: {
        color: '#016AE3',
        textTransform: 'none',
        marginTop: '12px',
        fontSize: '12px',
        border: '1px solid #016AE3',
        '&:hover': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: 'none',
        },
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    boxWrapperHalf: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        maxHeight: '168px',
        minHeight: '168px',
    },
    bottomMargin: {
        marginBottom: theme.spacing(2),
    },
    rightMargin: {
        marginRight: theme.spacing(2),
    },
    loader: {
        color: '#016AE3',
    },
    gridWrapper: {
        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
    },
}); });
var Actions = function (props) {
    var classes = useStyles();
    var _a = useState(false), downloadInProgress = _a[0], setDownloadInProgress = _a[1];
    var application = props.application;
    var currentState = get(application, 'current_status.state_id', 0);
    var statusesId = get(application, 'statuses', []).map(function (s) { return s.state_id; });
    var enableDownloadDrs = !downloadInProgress &&
        (statusesId.includes(APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id) ||
            statusesId.includes(APPLICATION_STATES.TIER2_APPROVAL_COMPLETED.id));
    var showShareFeedbackButton = enableDownloadDrs && !props.isFeedbackReceived;
    var theme = useTheme();
    var isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
    var enableResubmitDesignDocument = [
        APPLICATION_STATES.RESUBMISSION_REQUESTED.id,
        APPLICATION_STATES.TIER2_RESUBMISSION_REQUESTED.id,
    ].includes(currentState);
    var approvalDocuments = get(application, 'approval_documents', []) || [];
    var downloadFileNames = [];
    approvalDocuments.forEach(function (document) { return downloadFileNames.push(document.file_name); });
    var handleDownload = function () {
        setDownloadInProgress(true);
        if (downloadFileNames.length > 1) {
            props.downloadAll({
                drsId: get(props.application, 'drs_id', ''),
                type: 'ADMIN',
                successCbk: function () {
                    setDownloadInProgress(false);
                    props.handleNps();
                },
                approvalDocs: true,
                application: props.application,
            });
        }
        else {
            props.getS3DownloadURL({
                drsId: props.application.drs_id,
                fileName: downloadFileNames,
                successCb: function (preSignedUrls, name) { return __awaiter(void 0, void 0, void 0, function () {
                    var urls;
                    return __generator(this, function (_a) {
                        urls = Object.keys(preSignedUrls).map(function (key) { return preSignedUrls[key]; });
                        urls.map(function (urlToDownload, ind) { return __awaiter(void 0, void 0, void 0, function () {
                            var url, a;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, fetch(urlToDownload)
                                            .then(function (response) { return response.blob(); })
                                            .then(function (blob) { return URL.createObjectURL(blob); })];
                                    case 1:
                                        url = _a.sent();
                                        a = document.createElement('a');
                                        a.href = url;
                                        a.download = name;
                                        a.click();
                                        if (ind === urls.length - 1) {
                                            setDownloadInProgress(false);
                                            props.handleNps();
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                    });
                }); },
            });
        }
    };
    return (_jsx(Fragment, { children: _jsx(Grid, { container: true, direction: "row", children: _jsxs(Grid, { item: true, xs: 12, className: classes.gridWrapper, children: [_jsx(Accordion, { expanded: true, children: _jsx(AccordionDetails, { className: classes.columnFlex, children: _jsx("div", { className: clsx(!isMobileView && classes.panelWrapper), children: _jsx(Box, { className: clsx(classes.bottomPadding, classes.maxWidthBox), children: _jsx(Paper, { variant: "outlined", className: clsx(classes.boxWrapper, classes.displayFlexCenter), children: _jsxs(Box, { className: clsx(classes.displayFlexCenter, classes.flexDirectionColumn), children: [_jsx(IconButton, { disableRipple: true, disabled: !enableDownloadDrs, onClick: function () { return handleDownload(); }, children: _jsx(Solid, { disabled: !enableDownloadDrs }) }), downloadInProgress && _jsx(CircularProgress, { className: classes.loader, size: 20 }), _jsxs(Typography, { className: clsx(classes.buttons, enableDownloadDrs ? classes.highlightedColor : classes.disabledColor), onClick: function () { return handleDownload(); }, children: [I18n.t('installerAction.downloadReviewDoc'), ' '] }), showShareFeedbackButton && (_jsx(Button, { variant: "outlined", disableRipple: true, className: classes.buttonFeedback, onClick: props.handleNps, children: I18n.t('installerAction.shareYourFeedback') }))] }) }) }) }) }) }), _jsx(Accordion, { expanded: true, children: _jsx(AccordionDetails, { className: classes.columnFlex, children: _jsx("div", { className: clsx(!isMobileView && classes.panelWrapper), children: _jsx(Box, { className: clsx(
                                    // !isMobileView && classes.displayFlex,
                                    !isMobileView && classes.bottomPadding, classes.maxWidthBox), children: _jsx(Paper, { variant: "outlined", className: clsx(classes.boxWrapperHalf, 
                                        // !isMobileView && classes.rightMargin,
                                        classes.displayFlexCenter, classes.bottomMargin, 
                                        // isMobileView && classes.bottomMargin,
                                        !enableResubmitDesignDocument && classes.disabledColor), onClick: props.openResubmissionPopup, children: _jsxs(Box, { className: clsx(classes.displayFlexCenter, classes.flexDirectionColumn), children: [_jsx(IconButton, { disabled: !enableResubmitDesignDocument, disableRipple: true, children: _jsx(Forward, { disabled: !enableResubmitDesignDocument }) }), _jsx(Typography, { className: clsx(classes.buttons, enableResubmitDesignDocument ? classes.highlightedColor : classes.disabledColor), children: I18n.t('installerAction.resubmitDoc') })] }) }) }) }) }) })] }) }) }));
};
export default Actions;
