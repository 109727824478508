var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from 'react-redux';
/* eslint-disable */
import { DefaultHeader } from 'enlm_ui_common';
import { forwardTo, getToken, isValidToken, parseParams } from '../../utils/helper';
import { get } from 'lodash';
import { setSolargrafAccess } from '../../containers/Header/action';
var Header = function (props) {
    var search = get(props, 'location.search', '');
    var token = getToken();
    var urlToken = parseParams(search).token;
    if (!isValidToken(token) && isValidToken(urlToken)) {
        token = urlToken;
    }
    return _jsx(DefaultHeader, __assign({}, props, { forwardTo: forwardTo }));
};
var mapStateToProps = function (state) { return ({
    companyState: state.appReducer.companyState,
    einOptIn: state.appReducer.einOptIn,
    einOptOut: state.appReducer.einOptOut,
    country: state.appReducer.countryCode,
    company: state.appReducer.company,
    companyInfo: state.appReducer.companyInfo,
    featureList: state.appReducer.featureList,
    enlightenManagerToken: state.appReducer.enlightenManagerToken,
    accountDetail: state.appReducer.accountDetail,
    viewOnlyAccess: state.appReducer.viewOnlyAccess,
    viewCannotEdit: state.appReducer.viewCannotEdit,
    locale: state.appReducer.locale,
    preferencesData: state.appReducer.preferencesData,
    solargrafAccess: state.appReducer.solargrafAccess,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setSolargrafAccess: function (payload) { return dispatch(setSolargrafAccess(payload)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Header);
