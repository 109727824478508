var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, CircularProgress, } from '@material-ui/core';
import Icons from '../../../../containers/Chats/images';
import moment from 'moment';
import { cloneDeep, get, isEmpty } from 'lodash';
import CustomSnackBar from '../../../CustomSnackBar';
import { APPLICATION_STATES } from '../../../../utils/constant';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoDialog from '../../InfoDialog';
import I18n from '../../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    panelHead: {
        background: theme.tertiary.panelHead,
        color: theme.tertiary.mainText,
        minHeight: '40px !important',
        height: '40px',
        cursor: 'unset !important',
        marginTop: theme.spacing(1),
    },
    panelDetails: {
        flexDirection: 'column',
    },
    heading: {
        fontWeight: 'bold',
        flexBasis: '90%',
        flexShrink: 0,
    },
    listStyle: {
        width: '100%',
        maxHeight: '313px',
        minHeight: '313px',
        overflow: 'auto',
    },
    updateButton: {
        width: '100%',
        backgroundColor: '#00000061',
        color: '#ffffff',
        textTransform: 'none',
    },
    dateLabel: {
        position: 'absolute',
        right: theme.spacing(2),
        bottom: 0,
    },
    listItem: {
        position: 'relative',
    },
    loadingDiv: {
        textAlign: 'center',
        maxHeight: '313px',
        minHeight: '313px',
    },
    noDocumentsDiv: {
        textAlign: 'center',
        maxHeight: '313px',
        minHeight: '313px',
    },
    downloadLink: {
        textDecoration: 'underline',
        fontSize: '15px',
        letterSpacing: '0px',
        color: '#306FB2',
        opacity: 1,
        cursor: 'pointer',
        fontWeight: 700,
    },
    disabledDownload: {
        pointerEvents: 'none',
        textDecoration: 'underline',
        fontSize: '15px',
        letterSpacing: '0px',
        color: 'grey',
        opacity: 1,
    },
    documentIcon: {
        color: theme.tertiary.darkBlue,
        marginRight: theme.spacing(1.5),
        cursor: 'pointer',
    },
    displayFlex: {
        display: 'flex',
    },
}); });
var UploadedDocuments = function (props) {
    var classes = useStyles();
    var _a = useState(false), appDocsLoading = _a[0], setAppDocsLoading = _a[1];
    var _b = useState([]), appDocs = _b[0], setAppDocs = _b[1];
    var _c = useState(false), fileDownloadInProgress = _c[0], setFileDownloadInProgress = _c[1];
    var _d = useState(false), downloadError = _d[0], setDownloadError = _d[1];
    var _e = useState([]), downloadErrorMsgs = _e[0], setDownloadErrorMsgs = _e[1];
    var _f = useState(false), showInfoDialog = _f[0], setShowInfoDialog = _f[1];
    var _g = useState(''), infoDialogContent = _g[0], setInfoDialogContent = _g[1];
    var getApplicationDocuments = function () {
        setAppDocsLoading(true);
        var docs = [];
        if (get(props, 'application.admin_documents')) {
            var adminDocs = get(props, 'application.admin_documents');
            for (var i = 0; i < adminDocs.length; i++) {
                var doc = adminDocs[i];
                if (doc.file_name) {
                    docs.push({
                        name: doc.file_name,
                        sub: doc.tag,
                        file_names: [doc.file_name],
                        downloadInProgress: false,
                        id: "".concat(i, "_admin_documents"),
                        date: get(doc, 'added_date', ''),
                        comment: get(doc, 'comments', ''),
                    });
                }
            }
        }
        setAppDocs(docs);
        setAppDocsLoading(false);
    };
    var getIcon = function (doc) {
        var fName = doc.file_names[0];
        var fTypeArray = fName.split('.');
        var fType = fTypeArray[fTypeArray.length - 1];
        var iconArray = ['pdf', 'png', 'jpeg'];
        if (!iconArray.includes(fType)) {
            fType = 'document';
        }
        var Icon = Icons[fType];
        return _jsx(Icon, {});
    };
    var handleDownload = function (fileName) {
        return new Promise(function (resolve) {
            props.getS3DownloadURL({
                drsId: props.application.drs_id,
                fileName: fileName,
                successCb: function (preSignedUrls, name) { return __awaiter(void 0, void 0, void 0, function () {
                    var urls, url, a;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                urls = Object.keys(preSignedUrls).map(function (key) { return preSignedUrls[key]; });
                                return [4 /*yield*/, fetch(urls[0])
                                        .then(function (response) { return response.blob(); })
                                        .then(function (blob) { return URL.createObjectURL(blob); })];
                            case 1:
                                url = _a.sent();
                                a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                a.click();
                                resolve(true);
                                return [2 /*return*/];
                        }
                    });
                }); },
                failureCb: function () {
                    var errorMsgs = cloneDeep(downloadErrorMsgs);
                    errorMsgs.push("Download Failed - ".concat(fileName));
                    setDownloadError(true);
                    setDownloadErrorMsgs(errorMsgs);
                    resolve(false);
                },
            });
        });
    };
    var downloadFiles = function (doc) { return __awaiter(void 0, void 0, void 0, function () {
        var aDocs, idxOfDownloadingDoc, i, i, fName, currentState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    aDocs = cloneDeep(appDocs);
                    idxOfDownloadingDoc = 0;
                    for (i = 0; i < aDocs.length; i++) {
                        if (aDocs[i].id === doc.id) {
                            aDocs[i].downloadInProgress = true;
                            idxOfDownloadingDoc = i;
                        }
                    }
                    setAppDocs(aDocs);
                    setFileDownloadInProgress(true);
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < doc.file_names.length)) return [3 /*break*/, 4];
                    fName = doc.file_names[i];
                    return [4 /*yield*/, handleDownload(fName)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    aDocs[idxOfDownloadingDoc].downloadInProgress = false;
                    setAppDocs(aDocs);
                    setFileDownloadInProgress(false);
                    currentState = get(props.application, 'current_status.state_id', 0);
                    if ([APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id, APPLICATION_STATES.TIER2_APPLICATION_ASSIGNED.id].includes(currentState) &&
                        (props.userEmail === get(props.application, 'current_reviewer.user_info.email') || props.isServiceAdmin)) {
                        props.updateStatus();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getApplicationDocuments();
    }, [props.application]);
    return (_jsxs(Fragment, { children: [_jsx("div", { className: classes.listStyle, children: appDocsLoading === true ? (_jsxs("div", { className: classes.loadingDiv, children: [' ', _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), _jsx(CircularProgress, { size: 15 })] })) : appDocs.length > 0 ? (_jsxs(Fragment, { children: [_jsx(List, { children: appDocs.map(function (doc, idx) {
                                return (_jsxs(ListItem, { title: doc.name, className: classes.listItem, children: [_jsx(ListItemAvatar, { children: _jsxs(Avatar, { variant: "square", children: [" ", getIcon(doc), " "] }) }), _jsx(ListItemText, { primary: doc.name.substr(0, 23) + '...', secondary: "".concat(I18n.t('applicationView.uploadedOn') + ' ' + moment(doc.date).format('Do MMMM')), primaryTypographyProps: {
                                                style: {
                                                    fontSize: '14px',
                                                    color: '#524C4C',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    opacity: 1,
                                                    width: !isEmpty(doc.comment) ? '55%' : '60%',
                                                },
                                            }, secondaryTypographyProps: {
                                                style: {
                                                    fontSize: '9px',
                                                    color: '#524C4C',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    opacity: 0.7,
                                                    width: !isEmpty(doc.comment) ? '55%' : '60%',
                                                },
                                            } }), _jsxs(ListItemSecondaryAction, { className: classes.displayFlex, children: [!isEmpty(doc.comment) && (_jsx(Typography, { className: classes.documentIcon, onClick: function () {
                                                        setShowInfoDialog(true);
                                                        setInfoDialogContent(doc.comment);
                                                    }, children: _jsx(DescriptionIcon, {}) })), _jsx(Typography, { className: doc.downloadInProgress || fileDownloadInProgress
                                                        ? classes.disabledDownload
                                                        : classes.downloadLink, onClick: function () { return downloadFiles(doc); }, children: "Download" })] })] }, idx));
                            }) }), downloadError &&
                            downloadErrorMsgs.map(function (msg, idx) {
                                return _jsx(CustomSnackBar, { severity: 'error', message: msg }, idx);
                            })] })) : (_jsxs("div", { className: classes.noDocumentsDiv, children: [_jsx("br", {}), _jsx("br", {}), _jsx("br", {}), _jsxs(Typography, { variant: "caption", children: ["** ", I18n.t('applicationView.noDoc'), " **"] })] })) }), infoDialogContent && (_jsx(InfoDialog, { openDialog: showInfoDialog, handleClose: function () { return setShowInfoDialog(false); }, content: infoDialogContent }))] }));
};
export default UploadedDocuments;
