import { ActionTypes } from './constants';
export function setMobileView(isMobileView) {
    return { type: ActionTypes.SET_MOBILE_VIEW, isMobileView: isMobileView };
}
export function setEmailId(emailId) {
    return { type: ActionTypes.SET_EMAIL_ID, emailId: emailId };
}
export function setEnlightenManagerToken(enlightenManagerToken) {
    return { type: ActionTypes.SET_ENLIGHTEN_MANAGER_TOKEN, enlightenManagerToken: enlightenManagerToken };
}
export function requestFailure(error) {
    return { type: ActionTypes.REQUEST_FAILURE, error: error };
}
export function getAccountData(payload) {
    return { type: ActionTypes.ACCOUNT_DETAIL_REQUEST, payload: payload };
}
export function getSiteDetails(payload) {
    return { type: ActionTypes.GET_SITE_DETAILS_REQUEST, payload: payload };
}
export function setCompanyId(companyId) {
    companyId = parseInt(companyId);
    return { type: ActionTypes.SET_COMPANY_ID, companyId: companyId };
}
export function setFromITK(fromITK) {
    return { type: ActionTypes.SET_FROM_ITK, fromITK: fromITK };
}
export function setShowHidePage(showPage) {
    return { type: ActionTypes.SHOW_PAGE, showPage: showPage };
}
export function saveApplicationFilters(payload) {
    return { type: ActionTypes.SET_APPLICATIONS_FILTERS, payload: payload };
}
