var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, put, call } from 'redux-saga/effects';
import { putMethod } from '../../utils/api';
import { AdminActionTypes } from './constants';
import settings from '../../utils/settings';
import { getToken } from '../../utils/helper';
import { get as getLodash } from 'lodash';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateAdminDocsFlow() {
    var request, _a, files, drsId, successCbk, isApprovalDocument, response, success, status_1, result, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(AdminActionTypes.UPDATE_ADMIN_DOCS_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, files = _a.files, drsId = _a.drsId, successCbk = _a.successCbk, isApprovalDocument = _a.isApprovalDocument;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("applications/".concat(drsId, "/admin/documents?isApprovalDocument=").concat(isApprovalDocument)),
                        withBearer: true,
                        body: files,
                    })];
            case 3:
                response = _b.sent();
                success = response.success, status_1 = response.status, result = response.result;
                if (!(success && status_1 === 200)) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: AdminActionTypes.UPDATE_ADMIN_DOCS_SUCCESS,
                    })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: AdminActionTypes.UPDATE_ADMIN_DOCS_FAILURE,
                    message: result.message || result.messages,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_1 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: updateAdminDocsFlow:', error_1);
                return [4 /*yield*/, put({ type: AdminActionTypes.UPDATE_ADMIN_DOCS_FAILURE, message: error_1 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
export function updateStatusFlow() {
    var request, _a, stateId, drsId, successCbk, response, success, status_2, result, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(AdminActionTypes.UPDATE_STATUS_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, stateId = _a.stateId, drsId = _a.drsId, successCbk = _a.successCbk;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("admin/".concat(drsId, "/status?status_ordinal=").concat(stateId)),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                success = response.success, status_2 = response.status, result = response.result;
                if (!(success && status_2 === 200)) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: AdminActionTypes.UPDATE_STATUS_SUCCESS,
                    })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: AdminActionTypes.UPDATE_STATUS_FAILURE,
                    message: result.message || result.messages,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: updateStatusFlow:', error_2);
                return [4 /*yield*/, put({ type: AdminActionTypes.UPDATE_STATUS_FAILURE, message: error_2 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
export function requestTier2Flow() {
    var request, _a, drsId, successCbk, response, success, status_3, result, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(AdminActionTypes.REQUEST_TIER2_APPROVAL_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, drsId = _a.drsId, successCbk = _a.successCbk;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("admin/".concat(drsId, "/tier2Approval")),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                success = response.success, status_3 = response.status, result = response.result;
                if (!(success && status_3 === 200)) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: AdminActionTypes.REQUEST_TIER2_APPROVAL_SUCCESS,
                    })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: AdminActionTypes.REQUEST_TIER2_APPROVAL_FAILURE,
                    message: result.message || result.messages,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_3 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: updateStatusFlow:', error_3);
                return [4 /*yield*/, put({ type: AdminActionTypes.REQUEST_TIER2_APPROVAL_FAILURE, message: error_3 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
var download = function (drsId, type, successCbk, approvalDocs, application) { return __awaiter(void 0, void 0, void 0, function () {
    var requestOptions, res, blob, newBlob, newUrl, link, fileName, approvalDoc;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                requestOptions = {
                    method: 'GET',
                    headers: { Authorization: "Bearer ".concat(getToken()) },
                };
                return [4 /*yield*/, fetch(settings.getDrsUrl("applications/".concat(drsId, "/allDocs?user_type=").concat(type, "&approval_docs=").concat(approvalDocs)), requestOptions)];
            case 1:
                res = _a.sent();
                return [4 /*yield*/, res.blob()];
            case 2:
                blob = _a.sent();
                newBlob = new Blob([blob]);
                newUrl = window.URL.createObjectURL(newBlob);
                link = document.createElement('a');
                link.href = newUrl;
                fileName = '';
                if (type === 'INSTALLER') {
                    fileName = 'installer_documents.zip';
                }
                else if (!approvalDocs) {
                    fileName = 'admin_documents.zip';
                }
                else {
                    approvalDoc = getLodash(application, 'approval_documents', [])[0];
                    fileName = drsId + '_Design_Docs_' + moment(approvalDoc.added_date).format('DDMMYYYY') + '.zip';
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                successCbk();
                return [2 /*return*/];
        }
    });
}); };
export function downloadAllFlow() {
    var request, _a, drsId, type, _b, approvalDocs, successCbk, application, error_4;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!true) return [3 /*break*/, 6];
                return [4 /*yield*/, take(AdminActionTypes.DOWNLOAD_ALL_FILES_REQUEST)];
            case 1:
                request = _c.sent();
                _a = request.payload, drsId = _a.drsId, type = _a.type, _b = _a.approvalDocs, approvalDocs = _b === void 0 ? false : _b, successCbk = _a.successCbk, application = _a.application;
                _c.label = 2;
            case 2:
                _c.trys.push([2, 3, , 5]);
                download(drsId, type, successCbk, approvalDocs, application);
                return [3 /*break*/, 5];
            case 3:
                error_4 = _c.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: downloadAllFlow:', error_4);
                return [4 /*yield*/, put({ type: AdminActionTypes.DOWNLOAD_ALL_FILES_FAILURE, message: error_4 })];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 0];
            case 6: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setStatusToUnderReviewFlow() {
    var request, _a, drsId, successCbk, response, success, status_4, result, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(AdminActionTypes.SET_STATUS_TO_UNDER_REVIEW_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, drsId = _a.drsId, successCbk = _a.successCbk;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("admin/".concat(drsId, "/underReview")),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                success = response.success, status_4 = response.status, result = response.result;
                if (!(success && status_4 === 200)) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: AdminActionTypes.SET_STATUS_TO_UNDER_REVIEW_SUCCESS,
                        application: result,
                    })];
            case 4:
                _b.sent();
                successCbk();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({
                    type: AdminActionTypes.SET_STATUS_TO_UNDER_REVIEW_FAILURE,
                    message: result.message || result.messages,
                })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_5 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: setStatusToUnderReviewFlow:', error_5);
                return [4 /*yield*/, put({ type: AdminActionTypes.SET_STATUS_TO_UNDER_REVIEW_FAILURE, message: error_5 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
